.chat-msg {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.bot-msg {
  justify-content: flex-start;
}

.user-msg {
  justify-content: flex-end;
}